import React from 'react';
import { LinkButtonPrimary } from './ui';
import Img from 'gatsby-image';

type FeaturedProps = {
    headline: string;
    text: string;
    image: any;
    overline?: string;
};

type CTAProps = {
    headline: string;
    subheadline?: string;
    text: string;
    buttonText?: string;
    buttonLink?: string;
    image: any;
};

const item = { hidden: { x: -10, opacity: 0 } };

export const FeatureImageRight = ({ headline, text, image, overline }: FeaturedProps) => (
    <div className="flex flex-col md:flex-row mb-24 md:mb-32 lg:mb-34 lg:pl-24 max-w-full">
        <div className="flex flex-col justify-center lg:ml-8 leading-relaxed w-full md:w-2/4">
            <div className="mb-6 md:mb-0">
                {overline && <p className="text-2xs font-medium uppercase tracking-wider mb-6">{overline}</p>}
                <h2 className="text-2xl md:text-4xl mb-5 font-bold">{headline}</h2>
                <p className="text-sm md:text-base leading-relaxed">{text}</p>
            </div>
        </div>
        <div className="md:ml-24 md:pl-4 w-2/4 md:w-6/12">
            <img src={image} alt="Illustration" className="object-contain w-full h-full" />
        </div>
    </div>
);

export const FeatureImageLeft = ({ headline, text, image, overline }: FeaturedProps) => (
    <div className="flex flex-col-reverse md:flex-row mb-24 md:mb-32 lg:mb-34 lg:pr-24 max-w-full">
        <div className="md:mr-32 md:pr-4 w-2/4 md:w-6/12">
            <img src={image} alt="Illustration" className="object-contain w-full h-full" />
        </div>
        <div className="flex flex-col justify-center lg:mr-8 leading-relaxed w-full md:w-2/4">
            <div className="mb-6 md:mb-0">
                {overline && <p className="text-2xs font-medium uppercase tracking-wider mb-6">{overline}</p>}
                <h2 className="text-2xl md:text-4xl mb-5 font-bold">{headline}</h2>
                <p className="text-sm md:text-base leading-relaxed">{text}</p>
            </div>
        </div>
    </div>
);

export const FeatureCTAImageRight = ({
    headline,
    subheadline,
    text,
    buttonText,
    buttonLink = '#',
    image,
}: CTAProps) => (
    <div className="flex flex-col md:flex-row max-w-full mb-24 md:mb-32 lg:mb-34 items-start relative z-10">
        <div className="flex flex-col justify-center leading-relaxed w-full md:w-6/12">
            <div className="mb-6 md:mb-0">
                <h2 className="text-lg md:text-lg mb-1 uppercase tracking-wide font-medium">{headline}</h2>
                {subheadline && <p className="font-light italic">{subheadline}</p>}
                <p className="text-xl lg:text-2xl mt-12 mb-8">{text}</p>
                {buttonText && <LinkButtonPrimary text={buttonText} link={buttonLink} />}
            </div>
        </div>
        <div className="md:ml-24 lg:ml-32 w-2/4 md:w-4/12">
            <Img fluid={image} className="w-full shadow-lg" />
        </div>
    </div>
);

export const FeatureCTAImageLeft = ({ headline, subheadline, text, buttonText, buttonLink = '#', image }: CTAProps) => (
    <div className="flex flex-col-reverse max-w-full mb-24 md:mb-32 lg:mb-34 md:flex-row md:items-center relative z-10">
        <div className="md:mr-10 lg:mr-24 md:-ml-15 lg:-ml-20 w-3/4 md:w-2/4">
            <Img fluid={image} className="rounded-full shadow-lg" />
        </div>
        <div className="flex flex-col justify-center leading-relaxed w-full md:w-6/12">
            <div className="mb-6 md:mb-0">
                <h2 className="text-lg md:text-lg mb-1 uppercase tracking-wide font-medium">{headline}</h2>
                {subheadline && <p className="font-light italic">{subheadline}</p>}
                <p className="text-xl lg:text-2xl mt-8 mb-8">{text}</p>
                {buttonText && <LinkButtonPrimary text={buttonText} link={buttonLink} />}
            </div>
        </div>
    </div>
);
