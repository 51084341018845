import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

type EncodeProps = {
    [name: string]: string;
};

const encode = (data: EncodeProps) => {
    return {
        fields: Object.keys(data).map((key) => ({ name: key, value: data[key] })),
        context: {
            pageUri: 'www.dfp-tech.ch',
            pageName: 'DFP',
        },
    };
};

export default () => {
    const [values, setValues] = useState({
        salutation: '',
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        phone: '',
    });

    const [showPhone, setShowPhone] = useState(false);
    const [sent, setSent] = useState(false);
    const [valid, setValid] = useState(true);

    const isValid = () => {
        return Boolean(values.salutation && values.firstname && values.lastname && values.message && values.message);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement & HTMLInputElement & HTMLSelectElement>) => {
        const { name, value } = e.currentTarget;
        setValues({ ...values, [name]: value });
        setValid(true);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        if (isValid()) {
            fetch(
                'https://api.hsforms.com/submissions/v3/integration/submit/4279246/404a049d-eab8-4712-8a9a-6a35a9040064',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(encode(values)),
                }
            )
                .then(() => setSent(true))
                .catch((error) => console.log(error));
        } else {
            setValid(false);
        }
        e.preventDefault();
    };

    const togglePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowPhone(!showPhone);
    };

    return (
        <AnimatePresence>
            <motion.div className="block shadow-lg rounded bg-white p-6" animate>
                <form className="" method="POST" action="/" name="contact" onSubmit={handleSubmit}>
                    <div className="relative mb-4">
                        <select
                            className="form-select bg-white appearance-none border-2 border-gray-200 rounded-sm w-full py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-cyan"
                            name="salutation"
                            onChange={handleChange}
                        >
                            <option value="">Anrede (bitte wählen)</option>
                            <option>Herr</option>
                            <option>Frau</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <input
                            className="bg-white appearance-none border-2 border-gray-200 rounded-sm w-full py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-cyan"
                            id="firstname"
                            type="text"
                            placeholder="Vorname"
                            name="firstname"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="bg-white appearance-none border-2 border-gray-200 rounded-sm w-full py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-cyan"
                            id="lastname"
                            type="text"
                            placeholder="Name"
                            name="lastname"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="bg-white appearance-none border-2 border-gray-200 rounded-sm w-full py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-cyan"
                            id="email"
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <textarea
                            className="bg-white appearance-none border-2 border-gray-200 rounded-sm w-full py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-cyan"
                            id="message"
                            placeholder="Nachricht"
                            name="message"
                            rows={10}
                            cols={50}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block">
                            <input className="form-checkbox mr-4 text-yellow" type="checkbox" onChange={togglePhone} />
                            <span className="">Ich wünsche einen Rückruf</span>
                        </label>
                    </div>
                    <AnimatePresence>
                        {showPhone && (
                            <motion.div
                                className="mb-4"
                                initial={{ y: -10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2, ease: 'easeInOut' }}
                            >
                                <input
                                    className="bg-white appearance-none border-2 border-gray-200 rounded-sm w-full py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-cyan"
                                    id="phone"
                                    type="text"
                                    placeholder="Telefon"
                                    name="phone"
                                    onChange={handleChange}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <div className="flex flex-auto justify-center">
                        {!valid && <div className="pb-3 text-red-500">Bitte füllen Sie alle Felder aus.</div>}
                    </div>
                    <div className="flex flex-auto justify-center">
                        {sent ? (
                            <motion.p
                                className="text-yellow-500"
                                initial={{ y: -50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.3, delay: 0.2 }}
                            >
                                Vielen Dank. Wir melden uns in Kürze.
                            </motion.p>
                        ) : (
                            <motion.button
                                className="inline-flex bg-yellow-400 text-cyan-700 hover:bg-yellow-500 hover:text-white hover:shadow-xl text-sm font-semibold py-2 px-4 rounded-full py-3 px-10 focus:outline-none focus:shadow-outline shadow-lg mr-8 mb-4"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.9 }}
                                type="submit"
                                disabled={!valid}
                            >
                                Absenden
                            </motion.button>
                        )}
                    </div>
                </form>
            </motion.div>
        </AnimatePresence>
    );
};
