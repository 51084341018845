import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import Logo from '../images/logo.svg';
import circle from '../images/circle.svg';
import { LinkButtonPrimary, LinkButtonSecondary } from '../components/ui';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

type Props = {
    image: any;
};

export default ({ image }: Props) => {
    return (
        <>
            <div className="container mx-auto pt-12 mb-24 md:mb-32 flex items-center justify-center md:justify-start z-10">
                <Link to={`/`}>
                    <img src={Logo} alt="Logo" className="object-contain w-full h-full mb-0" />
                </Link>
            </div>
            <div className="container relative mx-auto flex flex-col md:flex-row items-start mb-24 md:mb-34">
                <motion.div
                    className="absolute circle-top z-0 pointer-events-none"
                    animate={{ y: [0, 20, 0] }}
                    transition={{ duration: 8, ease: 'easeInOut', loop: Infinity }}
                >
                    <img src={circle} alt="circle" className="object-contain w-full h-full" />
                </motion.div>
                <motion.div
                    className="flex items-center md:mr-12 w-full md:w-3/5 z-10"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="mb-4">
                        <p className="text-2xs font-medium uppercase tracking-wider mb-5">Digital Finance Platform</p>
                        <h1 className="m-auto mb-2 text-4xl md:text-5xl font-bold leading-tight">
                            Digitaler Kreditprozess für Ihre Kunden.
                        </h1>
                        <h2 className="m-auto text-4xl md:text-5xl font-light mb-5 leading-tight">Wir haben ihn.</h2>
                        <LinkButtonPrimary text="Warum d.fp?" link="#whydfp" />
                        <LinkButtonSecondary text="Kontakt aufnehmen" link="#contactgroup" />
                    </div>
                </motion.div>
                <div className="w-2/4 md:w-2/6 mt-12 self-center relative">
                    <Img fluid={image} className="shadow-lg w-full" />
                </div>
            </div>
        </>
    );
};
