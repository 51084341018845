import React from "react"
import { LinkButtonPrimary } from "./ui"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import ContactForm from "./contactform"

type stepsProps = {
  number: string
  text: string
}

export const H3 = styled.h3`
  ${tw`text-3xl md:text-2xl mb-2 font-semibold`}
`
export const P = styled.p`
  ${tw`mb-2 text-sm`}
`
const Steps = ({ number, text }: stepsProps) => (
  <div className="flex flex-row items-start mb-5">
    <span className="text-4xl md:text-5xl lg:text-7xl leading-none text-yellow-400 font-bold mr-6 md:mr-8">{number}</span>
    <span className="md:pt-2 text-lg lg:text-xl">{text}</span>
  </div>
)

export const ContactGroup = () => (
         <div
           id="contactgroup"
           className="container mx-auto mb-32 flex flex-col md:flex-row items-start justify-between"
         >
           <div className="flex flex-col w-full md:w-2/4 md:mr-12 md:pr-4 mb-8 md:mb-2">
             <div>
               <h2 className="text-3xl md:text-4xl lg:text-5xl mb-4 font-bold leading-tight">
                 Der digitaler Kreditprozess für Ihre KMU Kunden.
               </h2>
               <h3 className="text-3xl md:text-4xl lg:text-5xl mb-8 font-light">
                 Die nächsten Schritte:
               </h3>
               <div className="flex flex-col">
                 <Steps number={`1`} text={`Nehmen Sie mit uns Kontakt auf`} />
                 <Steps number={`2`} text={`Wir demonstrieren Ihnen unsere Fähigkeiten`} />
                 <Steps
                   number={`3`}
                   text={`Bringen Sie Ihr KMU Geschäft weiter mit den Modulen aus dem d.fp Baukasten`}
                 />
               </div>
             </div>
           </div>
           <div className="flex w-full md:w-5/12">
             <ContactForm />
           </div>
         </div>
       )
