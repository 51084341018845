import React from 'react';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { graphql } from 'gatsby';
import Hero from '../components/hero';
import { IndexQuery } from '../../types/graphql-types';
import {
    FeatureImageLeft,
    FeatureImageRight,
    FeatureCTAImageRight,
    FeatureCTAImageLeft,
} from '../components/featuregroup';

import kreditpruefung from '../images/kreditpruefung.svg';
import kmukredit from '../images/kmukredit.svg';
import uptodate from '../images/uptodate.svg';
import circle from '../images/circle.svg';
import { ContactGroup } from '../components/contactgroup';
import { Grid } from '../components/grid';

type Props = {
    data: IndexQuery;
};

export default ({ data }: Props) => {
    return (
        <Layout siteTitle={data.site?.siteMetadata?.title || ''}>
            <SEO
                title={data.site?.siteMetadata?.title || ''}
                keywords={[`DFP`, `Swisspeers`, `Digitaler Kreditprozess`, `Schweizer Bank`, `KMU-Kredit`, `Kredit`]}
            />
            <div className="bg-gradient-top">
                <Hero image={data.tabletRequest?.childImageSharp?.fluid} />
                <motion.div
                    id="whydfp"
                    className="container mx-auto block items-center relative z-10"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <FeatureImageLeft
                        headline={`KMU-Kredit 100% online.`}
                        text={`Online 24/7 beantragen Kunden auf Ihrer Website genau dann einen KMU Kredit, wenn es
zeitlich passt. Die Informationen zur Ermittlung von Kreditfähigkeit und Würdigkeit werden
im Self-Servicing erfasst. Eine Ratingindikation gibt sofort Auskunft über die Höhe der zu
erwartenden Kosten.`}
                        image={kmukredit}
                        overline={`Für Schweizer Kommerzbanken`}
                    />
                    <FeatureImageRight
                        headline={`Kredite bis CHF 500.000 schnell geprüft`}
                        text={`Die Kreditwürdigkeitsprüfung findet unter Einbezug von integrierten Auskunftsdaten und
Social Media Screenings statt. Das quantitativen Rating Modell der Bank wird integriert.`}
                        image={kreditpruefung}
                        overline={`Prozesseffizienz für Ihre Kreditprüfung`}
                    />
                    <FeatureImageLeft
                        headline={`Immer auf dem neuesten Stand`}
                        text={`Wie bei Microsoft 365 befindet sich die Technologie immer auf dem neusten Stand
Dank Bereitstellung via Schweizer Cloud Service. Updates werden kontinuierlich und ohne
Unterbrüche eingespielt.`}
                        image={uptodate}
                        overline={`Software as a Service für Ihre IT`}
                    />
                </motion.div>
            </div>

            <div className="relative bg-gradient-bottom">
                <div className="container mx-auto flex flex-col items-center">
                    <FeatureCTAImageRight
                        headline={`Swisspeers AG setzt auf d.fp`}
                        text={`Die Technologieplattform von d.fp funktioniert seit 2016 ohne Unterbrüche mit steigenden
Kreditvolumen und Nutzerzahlen einwandfrei. Die Funktionalitäten auch für komplexere
Finanzierungsanfragen sind vorhanden. Die modernsten Sicherheitsstandards sind im
Einsatz und werden regelmässig extern überprüft.`}
                        image={data.tabletSwisspeers?.childImageSharp?.fluid}
                        buttonText={`Swisspeers kennenlernen`}
                        buttonLink={'https://www.swisspeers.ch'}
                    />
                </div>
                <motion.div
                    className="absolute circle-center z-0 pointer-events-none"
                    animate={{ y: [0, 20, 0] }}
                    transition={{ duration: 8, ease: 'easeInOut', loop: Infinity }}
                >
                    <img src={circle} alt="circle" className="object-contain w-full h-full" />
                </motion.div>

                <div className="container mx-auto mb-24 md:mb-32 lg:mb-34 relative z-10">
                    <Grid></Grid>
                </div>

                <div className="container mx-auto flex flex-col items-center">
                    <FeatureCTAImageLeft
                        headline={`Wir sind d.fp`}
                        text={`d.fp bietet den modernsten KMU Kredit der Schweiz in Form von Technologie und Knowhow.
Die Personen hinter d.fp bringen das technische und fachliche Wissen, sowie viele Jahre Praxiserfahrung 
in den Bereichen Entwicklung und Betrieb von Standardsoftware für die Finanzindustrie, im Kommerzkreditgeschäft und dem Aufbau digitaler Geschäftsmodelle mit.`}
                        image={data.about?.childImageSharp?.fluid}
                        buttonText={`Team kennenlernen`}
                        buttonLink={`https://www.swisspeers.ch/ueber-uns#team`}
                    />
                </div>
            </div>

            <div className="bg-white relative">
                <ContactGroup />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query Index {
        about: file(relativePath: { eq: "images/about.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        tabletRequest: file(relativePath: { eq: "images/tablet-request.png" }) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        tabletSwisspeers: file(relativePath: { eq: "images/tablet-sp.png" }) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                image
                siteUrl
            }
        }
    }
`;
