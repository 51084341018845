import React from 'react';
import { LinkButtonPrimary } from './ui';
import styled from '@emotion/styled';
import tw from 'tailwind.macro';
import * as Feather from 'react-feather';

type FeaturedProps = {
    headline: string;
    text: string;
    image: any;
    overline?: string;
};

export const H3 = styled.h3`
    ${tw`text-xl md:text-2xl mb-4 font-bold`}
`;
export const P = styled.p`
    ${tw`mb-6 font-sm leading-relaxed`}
`;

export const Grid = () => (
    <>
        <h2 className="text-2xl md:text-4xl mb-8 md:pb-1 font-bold">Was steckt in d.fp?</h2>

        <div className="flex flex-col md:flex-row">
            <div className="bg-cyan-400 w-full md:w-4/6 mr-5 mb-5 p-7 pb-12 text-white shadow-lg rounded">
                <h3 className="text-xl text-white md:text-3xl mb-4 font-semibold">
                    Die digitale Finanzierungs-Plattform d.fp
                </h3>
                <p className="mb-6 md:text-xl text-white font-light">
                    d.fp ist eine modulare, integrierte Finanzierungsplattform zur Anbahnung, Prüfung, Vergabe und
                    Administration von KMU Finanzierungsanfragen. Dfp verfügt sowohl über FrontEnd Module als auch ein
                    Kreditverwaltungssystem (Backoffice).
                </p>
                <p className="mb-6 md:text-xl text-white font-light">
                    d.fp unterstützt neben traditionellen Krediten und Hypotheken auch Mezzanine Finanzierungen.
                </p>
                <p className="mb-6 md:text-xl text-white font-light">
                    Mit der Möglichkeit, Finanzierungen als ERC 20 Kontrakte auf der Ethereum Blockchain zu verwalten,
                    werden Kredite zu digitalen Vermögenswerten, welche auch gehandelt werden können. So erhalten KMU
                    Zugang zu einem neuartigen Kapitalmarkt.
                </p>
            </div>
            <div className="bg-white w-full md:w-2/6 mb-5 p-7 pb-12 shadow-lg rounded">
                <H3>Online Kreditantrag</H3>
                <P>
                    Der Online Kreditantrag erlaubt Ihrem Kunden direkt zu einem provisorischen Rating und damit einer
                    Kostenabschätzung für sein Finanzierungsbedürfnis zu gelangen. Dabei wird er unterstützt durch die
                    Plattform
                </P>
                <ul className="list-none">
                    <li className="ml-4">
                        <Feather.Check className="text-yellow-400 inline -ml-6 mr-1" />
                        indem Firmeninformationen direkt aus dem ZEFIX ergänzt werden und
                    </li>
                    <li className="ml-4">
                        <Feather.Check className="text-yellow-400 inline -ml-6 mr-1" />
                        die Integration diverser Buchhaltungssysteme die Erfassung von Finanzzahlen erübrigen.
                    </li>
                </ul>
                <P>
                    Als Resultat steht dem Kunden ein Ratingreport mit nachvollziehbaren Informationen zum
                    Ratingresultat zur Verfügung. Jetzt schickt er den Kreditantrag ab für die Kreditprüfung.
                </P>
            </div>
        </div>
        <div className="flex flex-col md:flex-row">
            <div className="bg-white w-full md:w-2/6 mb-5 md:mr-5 p-7 pb-12 shadow-lg rounded">
                <H3>Kunden Onboarding</H3>
                <P>Die Online Eröffnung eines Kreditnehmerkontos erfolgt</P>
                <ul className="list-none">
                    <li className="ml-4">
                        <Feather.Check className="text-yellow-400 inline -ml-6 mr-1" />
                        nach den Vorgaben aus dem FINMA Rundschreiben RS 2016/7 Video- und Online- Identifizierung und
                    </li>
                    <li className="ml-4">
                        <Feather.Check className="text-yellow-400 inline -ml-6 mr-1" />
                        geldwäschereikonform. Die Prozesse sind vom Verein für Qualitätssicherung von
                        Finanzdienstleitungen (VQF) revidiert worden. So identifizierte potentielle Kunden sind parat
                        für ein Bankgeschäft.
                    </li>
                </ul>
            </div>
            <div className="bg-white w-full md:w-4/6 mb-5 p-7 pb-12 shadow-lg rounded">
                <H3>Kreditprüfung</H3>
                <P>
                    Eingereichte Onlinekreditanträge werden im Backoffice Module durch die Kreditabteilung der Bank oder
                    durch d.fp Spezialisten weiterbearbeitet. Das beinhaltet die Vollständigkeitsprüfung und die
                    Verifikation der eingegebenen Daten.
                </P>
                <P>
                    Dabei stützt sich die Kreditfähigkeit auf das integrierte Kreditrisikomodell, die Kreditwürdigkeit
                    auf Daten externer Kreditbüros (CRIF) und ein integriertes, branchenabhängiges Social Media
                    screening. Als Resultat der Prüfung entsteht ein elektronisches Kreditdossier, welches zur
                    Finanzierung freigegeben werden kann.
                </P>
            </div>
        </div>
    </>
);
